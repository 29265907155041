import React from "react"

import { Image } from "components/anti/image/image"
import { Section, Container } from "components/anti/grid/grid"

import icon from "assets/img/brand/shift-icon.svg"
import gambar from "assets/img/common/Zoom.jpg"
import { Card } from "../../anti/card/card"

import graphic from "assets/img/common/grfx-grfx-floating-4.png"

export const AboutContent = ({ data }) => {
  const block = data

  return (
    <Section className="about-content py-0">
      <img src={graphic} className=" d-lg-block d-none graphic-about" />
      <Container>
        <div className="row">
          <div className="offset-lg-3 col-lg-9">
            <div
              dangerouslySetInnerHTML={{ __html: data?.text }}
              className="mb-5"
            />
            <div className="about-content-values">
              <div className="row">
                {block.values.map((value, i) => (
                  <div className="col-md-6" key={i}>
                    <h3 className="h2 font-weight-normal">{value?.label}</h3>
                    <h2 className="h2">{value?.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: value?.text }} />
                    <div className="d-none d-lg-block position-relative">
                      <Image
                        src={block?.image?.sourceUrl}
                        ratio="r-4-3"
                        alt="Shift"
                      />
                    </div>
                    <img
                      src={icon}
                      className="img-fluid icon"
                      alt="Shift Icon"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="d-block d-lg-none">
          <Image src={block?.image?.sourceUrl} ratio="r-4-3" alt="Shift" />
        </div>
      </Container>
    </Section>
  )
}
