import React from "react"

import { Section, Container } from "../../anti/grid/grid"
import { useScrollAnim } from "src/components/hooks/hooks"

export const AboutHistory = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  const block = data

  return (
    <Section forwardRef={trigger} className="about-history pt-0">
      <Container>
        <div className="w-md-600px">
          <h6 className={`h6 ${anim(2)}`}>{block?.label}</h6>
          <h2 className={`h2 ${anim(3)}`}>{block?.title}</h2>
        </div>
      </Container>
      <div className={`circle-graphic ${anim(2)}`} />
    </Section>
  )
}
