import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"

//Load Components
import { AboutCover } from "components/shift/about/cover"
import { AboutOverview } from "components/shift/about/overview"
import { AboutHistory } from "components/shift/about/history"
import { AboutContent } from "components/shift/about/about"
import { AboutValues } from "components/shift/about/values"
import { AboutTeam } from "components/shift/about/team"
import { AboutRecognitions } from "components/shift/about/recognitions"
import { AboutRequest } from "components/shift/about/request"

//Load Context
import { LangContext } from "../context/lang-context"

const AboutPage = () => {
  const { lang } = useContext(LangContext)
  const data = useStaticQuery(GET_ABOUT_DETAILS)
  const block =
    lang === "EN"
      ? data.wordPress.page.aboutPage
      : data.wordPress.page.translation.aboutPage ||
        data.wordPress.page.aboutPage
  const awards = data.wordPress.page.aboutPage
  return (
    <Layout>
      <Seo title="About" />
      <AboutCover data={block.cover} />
      <AboutOverview data={block.overview} />
      <AboutHistory data={block.history} />
      <AboutContent data={block.about} />
      <AboutValues data={block.values} />
      <AboutTeam data={block.team} />
      <AboutRecognitions data={awards.awards} text={block.awards} />
      <AboutRequest data={block.request} />
    </Layout>
  )
}

export default AboutPage

const GET_ABOUT_DETAILS = graphql`
  query About {
    wordPress {
      page(id: "about", idType: URI) {
        aboutPage {
          cover {
            label
            titleline1
            titleline2
            image {
              sourceUrl
            }
          }
          overview {
            text
          }
          history {
            title
            label
          }
          about {
            text
            image {
              sourceUrl
            }
            values {
              label
              text
              title
            }
          }
          values {
            title
            label
            text
            values {
              text
              title
              image {
                sourceUrl
              }
            }
          }
          team {
            label
            text
            title
            image {
              sourceUrl
            }
            button {
              text
              url
            }
          }
          awards {
            label
            text
            title
            button {
              url
              text
            }
            awards {
              ... on WordPress_Award {
                id
                award {
                  to
                  name
                  from
                  image {
                    sourceUrl
                  }
                }
              }
            }
          }
          request {
            title
            text
            button {
              text
              url
            }
            image {
              sourceUrl
            }
          }
        }
        translation(language: ID) {
          aboutPage {
            cover {
              label
              titleline1
              titleline2
              image {
                sourceUrl
              }
            }
            overview {
              text
            }
            history {
              title
              label
            }
            about {
              text
              image {
                sourceUrl
              }
              values {
                label
                text
                title
              }
            }
            values {
              title
              label
              text
              values {
                text
                title
                image {
                  sourceUrl
                }
              }
            }
            team {
              label
              text
              title
              image {
                sourceUrl
              }
              button {
                text
                url
              }
            }
            awards {
              label
              text
              title
              button {
                url
                text
              }
              awards {
                ... on WordPress_Award {
                  id
                  award {
                    to
                    name
                    from
                    image {
                      sourceUrl
                    }
                  }
                }
              }
            }
            request {
              title
              text
              button {
                text
                url
              }
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`
